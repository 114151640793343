.table-cell {
  padding: 8px !important;
}

.ant-radio-group .ant-radio-button-wrapper {
  z-index: 0;
}

.main-table {
  .ant-table-column-title {
    z-index: 0;
  }
}
