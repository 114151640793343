@import "src/styles/scss/main.scss";

.login-page {
  background-image: url("/assets/images/bg-login.png");
  position: absolute;
  /* Full height */
  width: 100vw;
  height: 100vh;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-headline {
  color: $--color-white;
  text-align: center;
  margin-top: 104px;
  font-size: 32px;
  font-weight: 700;
}

.login-wrapper {
  margin: 32px auto;
  background-color: white;
  height: 252px;
  width: 566px;
  border-radius: 16px;
}

.pigspin-logo {
  background-image: url("/assets/logo/pigspin-logo.svg");
  display: inline-block;
  width: 182px;
  height: 36px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 40px 192px 16px 192px;
}

.error-modal {
  width: 174px;
  height: 34px;
  display: block;
  margin: auto;
  margin-top: 16px;
}

.welcome-text {
  text-align: center;
  color: black;
  font-size: 16px;
  font-weight: 400;
}

.login-button {
  margin: 40px auto;
  padding: 2px;
  background-color: #597EF7;
  width: 350px;
  height: 58px;
  border-radius: 8px;
  display: block;
  font-size: 20px;
  font-weight: 700;

  &:focus {
    background-color: #597EF7;
  }

  &:hover {
    background-color: #2F54EB;
  }

  &:after, &:active{
    background-color: #1D39C4;
  }

  span {
    margin-top: 10px;
  }
}

.login-button-logo {
  background-image: url("/assets/logo/gmail-logo.svg");
  display: inline-block;
  width: 52px;
  height: 52px;
  float: left;
}

.login-error-modal {
  border-radius: 8px;

  .ant-modal-header {
    background-color: #F31641;
    padding: 12px 24px;
  }

  .modal-header {
    width: 48px;
    height:48px;
    margin: auto;
  }

  .modal-content-wrapper {
    text-align: center;
    display: grid;
    font-size: 16px;
    font-weight: 400;

    .body-headline {
      font-size: 24px;
      font-weight: 700;
      margin-top: 16px;
    }

    .body-description {
      display: contents;

      label:first-child {
        margin-top: 18px;
      }
    }

    .dismiss-button {
      background-color: #6F5ED7;
      margin: 32px auto 16px auto;
      display: block;
      font-size: 16px;
      width: 150px;
      height: 40px;
      padding: 8px;
      border-radius: 4px;
      box-shadow: 0px 2px 2px rgba(83, 29, 171, 0.1);
    }
  }
}

