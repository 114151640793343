.backoffice-radio-button {
  .ant-radio-button-wrapper {
    margin: 0 0.5rem;
    border-width: thin;
    background: white;
    color: #374550;
    border: 1px solid #85949E !important;
    border-radius: 100px;
    border: none;
    &:first-child {
      margin-left: 0;
      border-radius: 100px;
    }
    &::before {
      display: none;
    }
   
  }
  .ant-radio-button-wrapper-checked {
    background: #EBE8FF;
    color: #5B4ABF;
    border: 1px solid #5B4ABF !important;
    box-shadow: none;
  }
}