.RefundRequestModal {
  .ant-modal-title {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
  }

  .withdraw-modal-countdown {
    display: flex;
    justify-content: center;
    font-weight: bold;
  }

  .ant-input-affix-wrapper > input.ant-input {
    padding: 0 10px;
  }

  .ant-modal-body {
    padding: 24px 24px 12px 24px;
  }

  .content {
    text-align: center;
    padding-bottom: 24px;
  }

  .footer {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
  }

}
