.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	.clear-filter {
		&:hover {
			cursor: pointer;
		}
	}
}