.table-cell {
  padding: 8px !important;
}

.main-table {
  .ant-table-column-title {
    z-index: 0;
  }
}

.search-btn {
  width: 44px !important;
  height: 44px !important;
}
