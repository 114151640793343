.add-admin-user-modal {
  .ant-modal {
    height: 462px;
    width: 496px !important;
  }

  .ant-modal-header {
    div {
      text-align: center;
      font-weight: 700;
      font-size: 20px;
    }
  }

  .ant-modal-body {
    padding: 0px;
  }

  .ant-select-selection-search, .ant-select-selection-item, .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }

  .content-header {
    text-align: center;
    margin-bottom: 16px;
  }

  .form-wrapper {
    padding: 16px 83px;
  }

  .form-register {

    .ant-form-item-label {
      padding-bottom: 4px;

      label {
        color: #5F6D78;
      }
    }
    .ant-select-selector {
      height: 40px;
      border-radius: 4px;
    }

    .ant-input {
      font-size: 16px;
      height: 40px;
      border-radius: 4px;
    }
  }

  .footer {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #EEF1F3;

    .cancel-button {
      width: 100px;
      height: 40px;
      border-radius: 4px;
      border: 1px solid #85949E;
      box-shadow: 0px 2px 2px rgba(55, 69, 80, 0.1);
      box-sizing: border-box;

      span {
        font-size: 16px;
        font-weight: bold;
      }

      &:hover {
        background-color: #F8F6FF;
        color: #8F80EC;
      }
    }

    .confirm-button {
      background-color: #6F5ED7;
      height: 40px;
      width: 160px;
      box-shadow: 0px 2px 2px rgba(83, 29, 171, 0.1);
      border-radius: 4px;
      color: #FFFFFF;

      &:hover:enabled {
        background-color: #8F80EC;
      }

      &:disabled {
        opacity: 0.3;
      }

      span {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}
