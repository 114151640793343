.AddBankForm {
  .ant-row.ant-form-item {
    margin-bottom: 18px;
    &:nth-child(2){

    }
  }

  .ant-form-item-label {
    padding-bottom: 4px;
  }
}