.modal-body-wrapper {
  text-align: center;
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
  flex-direction: row;
  height: 99px;
  background: #F6F8FA;
}

.transaction-detail {
  margin-top: 16px;

  .title {
    font-size: 12px;
    color: #5F6D78;
  }

  .value {
    font-size: 16px;
    font-weight: 700;
  }
}
