
$--color-violet-100: #EFE6FD;
$--color-violet-200: #D0B3FA;
$--color-violet-300:#6F5ED7;
$--color-violet-400: #6200EE;
$--color-violet-500: #4E00BE;
$--color-violet-600: #310077;

$--color-orange-100: #FFF4E6;
$--color-orange-200: #FFDEB3;
$--color-orange-300: #FFB24D;
$--color-orange-400: #FF9100;
$--color-orange-500: #CC7400;
$--color-orange-600: #804900;

$--color-greyscale-100: #FCFCFC;
$--color-greyscale-200: #D4D4D5;
$--color-greyscale-300: #AAAAAB;
$--color-greyscale-400: #7F7F82;
$--color-greyscale-500: #595959;
$--color-greyscale-600: #2A2A2E;
$--color-greyscale-900: #0A0F14;

$--color-white: #FFFFFF;


// react-toastify
:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #EFFFF9;
  --toastify-color-warning: #FFF1F4;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-min-width: 240px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 52px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  //Used only for colored theme
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #0D9E6A;
  --toastify-text-color-warning: #C70227;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  // Used when no type is provided
  // toast("**hello**")
  --toastify-color-progress-light: linear-gradient(
                  to right,
                  #4cd964,
                  #5ac8fa,
                  #007aff,
                  #34aadc,
                  #5856d6,
                  #ff2d55
  );
  // Used when no type is provided
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}
