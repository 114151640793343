.desk-panel {
  padding: 16px;
  background: #FFFFFF;
  border-radius: 8px;

  .desk-panel-header {
    padding-bottom: 16px;
    width: 100%;

	.ant-space-item {
		width: 100%;
	}
  }
}