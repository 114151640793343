.bo-tabs {
  &.ant-tabs {
    .ant-tabs-nav::before {
      display: none;
    }
    .ant-tabs-nav .ant-tabs-tab {
      color: @gray-8;
      font-weight: 700;
      padding: 0;
      margin-top: 10px;
      + .ant-tabs-tab {
        margin-left: 24px;
      }
    }
  }

  &.ant-tabs-large {
    .ant-tabs-nav .ant-tabs-tab {
      font-size: 20px;
    }
  }
  &.ant-tabs-middle {
    .ant-tabs-nav .ant-tabs-tab {
      font-size: 14px;
      + .ant-tabs-tab {
        margin-left: 14px;
      }
    }
  }
  &.ant-tabs-small {
    .ant-tabs-nav .ant-tabs-tab {
      font-size: 12px;
      + .ant-tabs-tab {
        margin-left: 12px;
      }
    }
  }
}

@purple-1: #F8F6FF;@purple-2: #EBE8FF;@purple-3: #CFC7FF;@purple-4: #B3A7FC;@purple-5: #8F80EC;@purple-6: #6F5ED7;@purple-7: #5B4ABF;@purple-8: #3C2BA4;@purple-9: #2A1D79;@purple-10: #1A124C;@golden-purple-6: #722ED1;@gray-1: #FFFFFF;@gray-2: #FAFAFA;@gray-3: #F5F5F5;@gray-4: #F0F0F0;@gray-5: #D9D9D9;@gray-6: #BFBFBF;@gray-7: #8C8C8C;@gray-8: #595959;@gray-9: #262626;@gray-10: #000000;@cloud-1: #F6F8FA;@cloud-2: #EEF1F3;@cloud-3: #E2E9ED;@cloud-4: #C9D4DB;@cloud-5: #A7B6C0;@cloud-6: #85949E;@cloud-7: #5F6D78;@cloud-8: #374550;@cloud-9: #1F2B35;@cloud-10: #0A0F14;@green-1: #EFFFF9;@green-2: #C5F9E6;@green-3: #9AF8D6;@green-4: #57EFB9;@green-5: #29E2A0;@green-6: #18C889;@green-7: #0D9E6A;@green-8: #058054;@green-9: #005235;@green-10: #042116;@red-1: #FFF1F4;@red-2: #FFDAE1;@red-3: #FFB2C1;@red-4: #FF869D;@red-5: #FF4259;@red-6: #F31641;@red-7: #C70227;@red-8: #92001C;@red-9: #6B0014;@red-10: #37000B;@yellow-1: #FFFBE6;@yellow-2: #FFF1B8;@yellow-3: #FFE58F;@yellow-4: #FFD666;@yellow-5: #FFC53D;@yellow-6: #FAAD14;@yellow-7: #D48806;@yellow-8: #AD6800;@yellow-9: #AD6800;@yellow-10: #613400;@orange-6: #FA8C16;@blue-1: #F5F9FF;@blue-2: #D6E4FF;@blue-3: #ADC6FF;@blue-4: #85A5FF;@blue-5: #597EF7;@blue-6: #2F54EB;@blue-7: #1890FF;@blue-8: #10239E;@blue-9: #061178;@blue-10: #030852;@volcano-4: #FF9C6E;@volcano-6: #FA541C;@pink-6: #D858A6;@primary-color: @purple-6;@primary-color-outline: @cloud-6;@success-color: @green-6;@warning-color: @yellow-6;@text-color: @cloud-9;@heading-color: @cloud-10;@border-radius-base: 4px;@border-radius-base-md: 4px;@item-hover-bg: @purple-1;@item-active-bg: @purple-2;@label-color: @cloud-7;@switch-color: @green-6;@table-padding-horizontal: 8px;@typography-title-font-weight: 700;@danger-bg: @red-1;@danger-text: @red-7;@success-bg: @green-1;@success-text: @green-7;@warning-bg: @yellow-1;@warning-text: @yellow-7;@danger-color: @red-5;@info-color: @blue-4;@dark-color: @gray-9;@light-dark-color: @cloud-7;