#AffiliateOverview {
  .ant-table-tbody {
    .ant-table-cell.subTable {
      padding: 0 !important;
      td {
        text-align: right;
        padding: 8px;
      }
      tr:not(:last-child){
        td {
          border-bottom: 1px solid #f0f0f0;
        }
      }
    }
  }
}