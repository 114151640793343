@charset "UTF-8";

@font-face {
  font-family: "ps-backoffice";
  src:url("../assets/fonts/ps-backoffice.eot");
  src:url("../assets/fonts/ps-backoffice.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/ps-backoffice.woff") format("woff"),
    url("../assets/fonts/ps-backoffice.ttf") format("truetype"),
    url("../assets/fonts/ps-backoffice.svg#ps-backoffice") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "ps-backoffice" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "ps-backoffice" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-vector:before {
  content: "\e900";
}
.icon-filled-clear-2:before {
  content: "\61";
}
.icon-filled-down-1:before {
  content: "\62";
}
.icon-filled-expand-2:before {
  content: "\63";
}
.icon-filled-filter-1:before {
  content: "\64";
}
.icon-filled-hide-2:before {
  content: "\65";
}
.icon-filled-select-1:before {
  content: "\66";
}
.icon-filled-sorting-1:before {
  content: "\67";
}
.icon-filled-sorting-2:before {
  content: "\68";
}
.icon-filled-up-1:before {
  content: "\69";
}
.icon-outline-active-1:before {
  content: "\6a";
}
.icon-outline-add-1:before {
  content: "\6b";
}
.icon-outline-arrow-down-1:before {
  content: "\6c";
}
.icon-outline-arrow-left-1:before {
  content: "\6d";
}
.icon-outline-arrow-right-1:before {
  content: "\6e";
}
.icon-outline-arrow-up-1:before {
  content: "\6f";
}
.icon-outline-back-1:before {
  content: "\70";
}
.icon-outline-bank-1:before {
  content: "\71";
}
.icon-outline-bank-2:before {
  content: "\72";
}
.icon-outline-bank-account-name-1:before {
  content: "\73";
}
.icon-outline-bank-account-number-1:before {
  content: "\74";
}
.icon-outline-bank-balance-1:before {
  content: "\75";
}
.icon-outline-bank-manage-1:before {
  content: "\76";
}
.icon-outline-bonus-1:before {
  content: "\77";
}
.icon-outline-bonus-2:before {
  content: "\78";
}
.icon-outline-bottom-1:before {
  content: "\79";
}
.icon-outline-cash-1:before {
  content: "\7a";
}
.icon-outline-cash-wallet-1:before {
  content: "\41";
}
.icon-outline-check-1:before {
  content: "\42";
}
.icon-outline-check-1-1:before {
  content: "\43";
}
.icon-outline-clear-1:before {
  content: "\44";
}
.icon-outline-close-1:before {
  content: "\45";
}
.icon-outline-close-2:before {
  content: "\46";
}
.icon-outline-credit-add-1:before {
  content: "\47";
}
.icon-outline-credit-add-2:before {
  content: "\48";
}
.icon-outline-credit-remove-1:before {
  content: "\49";
}
.icon-outline-customer-manage-1:before {
  content: "\4a";
}
.icon-outline-date-1:before {
  content: "\4b";
}
.icon-outline-edit-1:before {
  content: "\4c";
}
.icon-outline-edit-history-1:before {
  content: "\4d";
}
.icon-outline-edit-history-2:before {
  content: "\4e";
}
.icon-outline-employee-manage-1:before {
  content: "\4f";
}
.icon-outline-expand-1:before {
  content: "\50";
}
.icon-outline-eye-1:before {
  content: "\51";
}
.icon-outline-eye-open-1:before {
  content: "\52";
}
.icon-outline-faq-manage-1:before {
  content: "\53";
}
.icon-outline-filter-2:before {
  content: "\54";
}
.icon-outline-filter-3:before {
  content: "\55";
}
.icon-outline-first-1:before {
  content: "\56";
}
.icon-outline-game-1:before {
  content: "\57";
}
.icon-outline-game-manage-1:before {
  content: "\58";
}
.icon-outline-hide-1:before {
  content: "\59";
}
.icon-outline-image-1:before {
  content: "\5a";
}
.icon-outline-info-1:before {
  content: "\30";
}
.icon-outline-last-1:before {
  content: "\31";
}
.icon-outline-linkline-1:before {
  content: "\32";
}
.icon-outline-loading-1:before {
  content: "\33";
}
.icon-outline-log-1:before {
  content: "\34";
}
.icon-outline-menu-1:before {
  content: "\35";
}
.icon-outline-menu-expand-1:before {
  content: "\36";
}
.icon-outline-menu-hide-1:before {
  content: "\37";
}
.icon-outline-mobile-1:before {
  content: "\38";
}
.icon-outline-more-1:before {
  content: "\39";
}
.icon-outline-next-1:before {
  content: "\21";
}
.icon-outline-notbank-2:before {
  content: "\22";
}
.icon-outline-noti-1:before {
  content: "\23";
}
.icon-outline-notify-1:before {
  content: "\24";
}
.icon-outline-notother-2:before {
  content: "\25";
}
.icon-outline-pause-1:before {
  content: "\26";
}
.icon-outline-qr-1:before {
  content: "\27";
}
.icon-outline-question-1:before {
  content: "\28";
}
.icon-outline-refresh-1:before {
  content: "\29";
}
.icon-outline-report-1:before {
  content: "\2a";
}
.icon-outline-report-2:before {
  content: "\2b";
}
.icon-outline-report-3:before {
  content: "\2c";
}
.icon-outline-report-4:before {
  content: "\2d";
}
.icon-outline-score-1:before {
  content: "\2e";
}
.icon-outline-search-1:before {
  content: "\2f";
}
.icon-outline-search-2:before {
  content: "\3a";
}
.icon-outline-slip-1:before {
  content: "\3b";
}
.icon-outline-stop-1:before {
  content: "\3c";
}
.icon-outline-talk-1:before {
  content: "\3d";
}
.icon-outline-time-1:before {
  content: "\3e";
}
.icon-outline-time-deposit-1:before {
  content: "\3f";
}
.icon-outline-top-1:before {
  content: "\40";
}
.icon-outline-transfer-1:before {
  content: "\5b";
}
.icon-outline-transfer-2:before {
  content: "\5d";
}
.icon-outline-transferback-1:before {
  content: "\5e";
}
.icon-outline-true-1:before {
  content: "\5f";
}
.icon-outline-unlinkline-1:before {
  content: "\60";
}
.icon-outline-user-info-1:before {
  content: "\7b";
}
.icon-outline-user-info-2:before {
  content: "\7c";
}
.icon-outline-warning-1:before {
  content: "\7d";
}
.icon-outline-zoom-1:before {
  content: "\7e";
}
.icon-outline-all-user-1:before {
  content: "\5c";
}
.icon-outline-add-2:before {
  content: "\e000";
}
.icon-outline-add-customer-1:before {
  content: "\e001";
}
.icon-outline-message-1:before {
  content: "\e002";
}
.icon-outline-user-1:before {
  content: "\e003";
}
.icon-outline-move-1:before {
  content: "\e004";
}
.icon-outline-ticket:before {
  content: "\e005";
}
.icon-outline-tag:before {
  content: "\e007";
}
