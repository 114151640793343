.sider-menu {
  background: #5F6D78;
  height: 100%;
  .ant-menu-item-group-title {
    color: #C9D4DB;
    padding-left: 25px;
    padding-top: 15px;
    transition: all;
  }

  .collapsed-title {
  .ant-menu-item-group-title {
      display: none;
    }
  }
  
  a {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 400;
    img {
      margin-right: 10px;
    }
  }
  a:hover {
    color: #FFFFFF;
  }
}

#AppSideBar {
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #374550;
  }
  .ant-menu-item:active {
    background-color: #374550;
  }
}

.avatar {
  cursor: pointer;
  width: 40px;
  height: 40px;
  &:hover {
    outline: solid 4px #EBE8FF;
    transition: outline 0.1s linear;
  }
  &:active {
    outline: solid 4px #CFC7FF;
  }
}

.avatar-menu {
  width: 200px;
  .ant-dropdown-menu-item-group-title {
    color: inherit;
    padding: 0;
    margin: 0;
  }
  .ant-dropdown-menu-item-group-list {
    padding: 0;
    margin: 0;
  }
  b {
    color: #1F2B35;
  }
}

.avatar-title > * {
  color: #5F6D78;
  padding: 5px 12px;
}

.authenticated-layout-button {
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  box-sizing: border-box;
  border-radius: 20px;
  cursor: pointer;
  width: 135px;
  height: 32px;
  font-size: 14px;

  &--check {
    @extend .authenticated-layout-button;
    background: #FFFFFF;
    border: 1px solid #85949E;
    box-shadow: 0px 2px 2px rgba(55, 69, 80, 0.1);
  }

  &--search {
    @extend .authenticated-layout-button;
    background: linear-gradient(225deg, #D151A7 0%, #A12EC3 100%);
    box-shadow: 0px 2px 2px rgba(83, 29, 171, 0.1);
    color: #fff;
  }

  &--register {
    @extend .authenticated-layout-button;
    background: #6F5ED7;
    border-radius: 4px;
    color: #fff;
    height: 36px;
  }

  &--edit-user {
    @extend .authenticated-layout-button;
    background: #5F6D78;
    width: 63px;
    height: 24px;
    color: #fff;
    border-radius: 4px;
    font-weight: bold;

    &:disabled {
      color: #fff !important;
      border-color: #5F6D78 !important;
      background: #5F6D78 !important;
      text-shadow: none;
      box-shadow: none;
      opacity: .3 !important;
    }

    &:focus {
      background: #5F6D78;
      border-color: #5F6D78;
      color: #FFFFFF;
    }

    &:hover {
      background: #85949E;
      border-color: #85949E;
      color: #FFFFFF;
    }
  }
}

.container {
  display: flex;
  align-items: center;
}
.container > * {
  margin: 0 12.5px;
}
.container-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.circle-button {
  background: #EEF1F3;
  color: #1F2B35;
  /* cloud/cloud-5 */

  box-shadow: 0px 2px 2px rgba(55, 69, 80, 0.1);
  border-radius: 20px;
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.ant-layout-header {
  background: #FFFFFF;
  height: 72px;
  padding: 20px 25px;
  line-height: 0;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1), inset 0px -1px 0px #E2E9ED;
}

.line {
  /* Rectangle 6 */
  height: 1px;
  left: 0px;
  right: 0px;
  top: 7px;
  padding: 0 !important;
  margin-bottom: 5px;
  /* Cloud / cloud-3 */
  background: #E2E9ED;

}
.content-layout-background {
  background: #F5F5F5;
  padding: 24px;
  margin: 0;
  min-height: 280px;
}