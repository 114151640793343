.search-result-wrapper {
  display: flex;
  justify-content: flex-start;
  background-color: #FAFAFA;

  &.matched {
    background-color: #EFFFF9;
  }
  &.not-matched {
    background-color: #FFF1F4;
  }

  .search-result-column {
    width: 400px;
    padding: 16px 24px 24px 24px;
    line-height: 20px;

    .title {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 8px;
    }

    .subtitle {
      font-weight: 700;
      margin-bottom: 8px;
    }

    .right-column {
      margin-top: 32px;
    }

    .last-item {
      margin-top: 16px;
    }
  }

  .search-result-value {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    .key {
      color: #5F6D78;
    }

    .value {
      font-weight: 700;
    }

    .bank {
      display: flex;

      .bank-logo {
        margin-left: 8px;
      }
    }
  }
}