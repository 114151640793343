.deposit-modal{
    width: 968px !important;

  .withdraw-modal-countdown {
    display: flex;
    justify-content: center;
    font-weight: bold;
  }

  .ant-modal-header {
    text-align: center;

    .ant-modal-title {
      font-size: 28px;
      font-weight: 700;
      line-height: 28px;
    }
  }

  .ant-modal-body {
    padding: 0px;

    .search-bar-wrapper {
      padding: 24px 83px;

      .text {
        font-size: 16px;
        font-weight: 700;
      }

      .search-bar {
        margin-top: 8px;
      }

      .empty-div {
        height: 244px;
      }


      .not-matched {
        background-color: #FFF1F4;
      }
    }
  }

  .ant-modal-footer {
    padding: 16px 24px;

    .icon {
      padding-top: 3px;
      margin-right: 9px;
    }
  }
}

.AlertModal--warning{
  border-radius: 8px 8px 0px 0px;
}

.AlertModal--error{
  border-radius: 8px 8px 0px 0px;
}

.AlertModal--success{
  border-radius: 8px 8px 0px 0px;
}
