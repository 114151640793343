.DepositDashboard {
  &--refund-modal-layout {
    margin-bottom: 24px;
  }
  &--row-header {
    display: flex;
    color: #85949E;
    font-weight: bold;
    div:first-child {
      flex-basis: 40%;
    }
  }
  &--row {
    display: flex;
    div:first-child {
      flex-basis: 40%;
    }
  }
  &--confirm-button {
    text-align: center;
    margin-top: 24px;
    button {
      padding: 0 30px;
    }
  }
}