.CustomButton {
  .custom-button-layout {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--primary {
    @extend .custom-button-layout;
    font-weight: bolder;
    border-radius: 4px;
    background-color: hsl(248, 60%, 61%);
    color: white;
    
    &:hover, &:focus {
      background-color: #6F5ED7;
      border-color: #6F5ED7;
      color: white;
      opacity: 0.7;
    }
  }

  &--secondary {
    @extend .custom-button-layout;
    font-weight: bolder;
    border-radius: 4px;
    background-color: white;
    color: #85949E;
    border-color: #85949E;

    &:hover, &:focus {
      background-color: white;
      color: #85949E;
      border-color: #85949E;
      opacity: 0.7;
    }
  }
}