.vip-history-modal .ant-modal-title{
  text-align: left;
}

.vip-history-modal .ant-statistic-title {
  color: white;
}

.vip-history-modal .ant-statistic,
.vip-history-modal .ant-statistic-content {
  color: white;
}