.AlertModal {
  overflow: hidden;
  .ant-modal-header {
    padding: 0;
    height: 72px;
  }

  .custom-header-layout {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 72px;
  }

  &--error {
    @extend .custom-header-layout;
    background-color: #F31641;
  }

  &--warning {
    @extend .custom-header-layout;
    background-color: #FFC53D;
  }

  &--success {
    @extend .custom-header-layout;
    background-color: #18C889;
  }

  .confirm-button {
    display: flex;
    justify-content: center;
  }

  .question-button {
    display: flex;
    justify-content: space-evenly;
  }
  
  .title {
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
  }

  .content {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    margin: 16px 0 32px 0;
  }
}